import * as React from "react";
import PatientLayout from "../components/patient/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import HcpIsi from "../components/hcp/isi/isi-content";

import "./safety.scss";

const SafetyPage = () => {
  return (
    <PatientLayout pageClass="patient-isi" noShowStickyIsi>
      <section>
        <Row>
          <Col xs={12}>
            <h1 className="first interior-first">IMPORTANT SAFETY INFORMATION</h1>
            <div className="page-isi">
              <HcpIsi />
            </div>
          </Col>
        </Row>
      </section>
    </PatientLayout>
  );
};

export default SafetyPage;

export const Head = () => <Seo title="Indication & Safety Info" description="Please read the Important Safety Information and Full Prescribing Information, including the Boxed Warning & Medication Guide, before taking Hysingla ER CII." />;
